import React from 'react';
import { Spinner } from '../../components/Spinner';
import { withLayout } from '../../containers/Layout';
import { useApi } from '../../hooks/useApi';
import { User } from '@felicetteapp/types';
const PageUsersBase = () => {
  const { response, fetching, error } = useApi<{
    users: Array<User>;
  }>('admin/users', { method: 'get' });
  return fetching ? (
    <Spinner />
  ) : error ? (
    <div className="px-2 py-2 text-center bg-red-500">ops!</div>
  ) : (
    <ul>
      {response.data.users.map((user) => (
        <li className="py-1">
          <small>{user.id}</small> : {user.email}
        </li>
      ))}
    </ul>
  );
};

const PageUsers = React.memo(PageUsersBase);
export default () => withLayout({ title: 'Users' }, <PageUsers />);
